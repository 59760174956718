// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-menu-js": () => import("./../../../src/pages/menu.js" /* webpackChunkName: "component---src-pages-menu-js" */),
  "component---src-pages-menu-test-js": () => import("./../../../src/pages/menuTest.js" /* webpackChunkName: "component---src-pages-menu-test-js" */),
  "component---src-pages-some-triangular-thoughts-js": () => import("./../../../src/pages/some-triangular-thoughts.js" /* webpackChunkName: "component---src-pages-some-triangular-thoughts-js" */),
  "component---src-templates-black-template-js": () => import("./../../../src/templates/black-template.js" /* webpackChunkName: "component---src-templates-black-template-js" */),
  "component---src-templates-white-template-js": () => import("./../../../src/templates/white-template.js" /* webpackChunkName: "component---src-templates-white-template-js" */)
}

